;(function($, window) {

    $(document).ready(function() {

        let slideshowHomeClass = '.fullwidth-slider',
            slideshowHome = UIkit.slideshow(slideshowHomeClass);

        if (slideshowHome) {
            setSlideshowRatio(slideshowHome);
        }

        $(window).resize(function() {
            setSlideshowRatio(slideshowHome);
        })

            // progressBar = document.getElementsByClassName('fullwidth-slider-progress')[0];
        //
        // if (slideshowHome) {
        //     let progressBarMax = progressBar.max,
        //         interval = slideshowHome.autoplayInterval / progressBarMax;
        //
        //     let increaseProgressBarValue = window.setInterval(function(e) {
        //         if (progressBar.value === 100) progressBar.value = 0;
        //         progressBar.value += 1;
        //     }, interval);
        // }

        // console.log($('.fullwidth-slider .uk-slideshow-items').css('min-height', $('.fullwidth-slider-image img').height()));

        let brandSliderActiveClass = 'brand-slider-active';

        UIkit.util.on('.brand-slider', 'itemshow', function(e) {
            $('.' + brandSliderActiveClass).removeClass(brandSliderActiveClass);
            $(e.target).addClass(brandSliderActiveClass);
        });
    });

    function setSlideshowRatio(slideshow) {
        let $slideshow = $('.fullwidth-slider'),
            desktopRatio = $slideshow.data('desktopRatio'),
            mobileRatio = $slideshow.data('mobileRatio');

        if (window.innerWidth <= 639) {
            slideshow.ratio = mobileRatio;
        } else {
            slideshow.ratio = desktopRatio;
        }
    }

    SmoothScroll({
        frameRate:150,
        animationTime:700,
        stepSize:100,
        pulseAlgorithm:1,
        pulseScale:4,
        pulseNormalize:1,
        accelerationDelta:50,
        accelerationMax:3,
        keyboardSupport:1,
        arrowScroll:50,
        fixedBackground:0
    });

})(jQuery, window);